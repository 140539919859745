import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";
declare var vad: any;

@Injectable({
  providedIn: 'root'
})
export class MicrophoneService {
    mediaStream: MediaStream | null = null
    mediaAudioRecorder: any | null = null
    microphoneStatus: boolean = false
    waveAnimationRenderEvent: Subject<any>
    closeMicrophoneEvent: Subject<File>
    positiveSpeechThreshold: number = 0.8
    speechEndTimeout: any = null;
    isMicAlreadyStarted = new BehaviorSubject<boolean>(false);

    constructor() {
        this.waveAnimationRenderEvent = new Subject<any>();
        this.closeMicrophoneEvent = new Subject<File>();
    }

    // getters
    getMicrophoneStatus (): boolean {
        return this.microphoneStatus;
    }
    getMicAlreadyStartedStatus (): Observable<boolean> {
        return this.isMicAlreadyStarted;
    }

    // setters
    setMicrophoneStatus (s: boolean) {
        this.microphoneStatus = s;
    }

    setMicAlreadyStartedStatus (mode: boolean) {
        this.isMicAlreadyStarted.next(mode);
    }

    async startMicrophone () {
        if (!this.isMicrophoneSupported()) return;

        try {
            await this.getMicrophoneAccess();
        } catch (err) {
            throw new Error(`could not get media stream: ${err}`);
        }
    }

    stopMicrophoneAccess () {
        (this.mediaStream?.getTracks() ?? []).forEach((track: MediaStreamTrack) => track.stop());
        (this.mediaAudioRecorder.stream.getTracks() ?? []).forEach((track: MediaStreamTrack) => track.stop());
        this.mediaAudioRecorder?.pause();

        this.mediaAudioRecorder = null;
        this.mediaStream = null;

        this.setMicrophoneStatus.call(this, false);
    }

    soundWaveSubscribe () {
        this.waveAnimationRenderEvent.asObservable();
    }

    closeMicrophoneSubscribe () {
        this.closeMicrophoneEvent.asObservable();
    }

    async getMicrophoneAccess () {
        try {
            this.setMicrophoneStatus(true);

            /*audio: {
                    //channelCount: 1,
                    echoCancellation: true,
                    //autoGainControl: true,
                    //noiseSuppression: true
             }*/
            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                }
            });

            this.mediaAudioRecorder = await this.getMicDriver(this.mediaStream);
            if (!this.mediaAudioRecorder) return;
            this.mediaAudioRecorder.start();
        } catch (err) {
            console.error(err);
            this.setMicrophoneStatus(false);
            throw new Error(`Could not initialize recording process: ${err}`);
        }
    }

    async getMicDriver(mediaStream: MediaStream) {
        let speechStarted = false;

        return await vad.MicVAD.new({
            stream: mediaStream,
            positiveSpeechThreshold: this.positiveSpeechThreshold,
            onSpeechStart: () => {
                speechStarted = true;
            },
            onSpeechEnd: (audio: Float32Array) => {
                speechStarted = false;
                this.closeMicrophoneEvent.next(
                    this.convertWavToFile(this.convertFloat32ArrayToWav(audio, 16000))
                );
            },
            onFrameProcessed: (probabilities: any) => {
                this.waveAnimationRenderEvent.next(probabilities);

                // TODO Using single mic feature
                /*if (!speechStarted && probabilities.isSpeech < this.positiveSpeechThreshold) {
                    if (this.speechEndTimeout === null) {
                        this.speechEndTimeout = setTimeout(() => {
                            this.closeMicrophoneEvent.next(
                                this.convertWavToFile(this.convertFloat32ArrayToWav(new Float32Array(), 16000))
                            );
                            // restart timer
                            this.speechEndTimeout = null;
                        }, 2000);
                    }
                } else if (probabilities.isSpeech > this.positiveSpeechThreshold) {
                    clearTimeout(this.speechEndTimeout);
                    this.speechEndTimeout = null;
                }*/
            }
        });
    }


    convertWavToFile (audio: Blob): File {
        return new File([audio], "audio.wav", {type: audio.type});
    }

    convertFloat32ArrayToWav (audioBuffer: Float32Array, sampleRate: number): Blob { // Algorithm that converts Float32Array -> Wav
        if (audioBuffer.length === 0) {
            return new Blob();
        }

        const bufferLength = audioBuffer.length;
        const wavHeaderSize = 44;
        const totalLength = bufferLength * 2 + wavHeaderSize;

        const dataView = new DataView(new ArrayBuffer(totalLength));
        const writeString = (view: DataView, offset: number, string: string) => {
            for (let i = 0; i < string.length; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
            }
        };

        writeString(dataView, 0, 'RIFF');
        dataView.setUint32(4, 36 + bufferLength * 2, true);
        writeString(dataView, 8, 'WAVE');

        writeString(dataView, 12, 'fmt ');
        dataView.setUint32(16, 16, true);
        dataView.setUint16(20, 1, true);
        dataView.setUint16(22, 1, true);
        dataView.setUint32(24, sampleRate, true);
        dataView.setUint32(28, sampleRate * 2, true);
        dataView.setUint16(32, 2, true);
        dataView.setUint16(34, 16, true);

        writeString(dataView, 36, 'data');
        dataView.setUint32(40, bufferLength * 2, true);

        let offset = 44;
        for (let i = 0; i < bufferLength; i++, offset += 2) {
            const s = Math.max(-1, Math.min(1, audioBuffer[i]));
            dataView.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
        }

        return new Blob([dataView], { type: 'audio/wav' });
    }

    isMicrophoneSupported() {
        return navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function';
    }

    loadScript(scriptUrl: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = scriptUrl;
            // @ts-ignore
            scriptElement.onload = resolve;
            scriptElement.onerror = reject;
            document.body.appendChild(scriptElement);
        });
    }
}
