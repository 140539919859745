import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { ComponentAbstract } from "app/components/component.abstract";
import { LanguageService } from "app/services/language.service";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent extends ComponentAbstract implements OnInit {
    companyLogo: string = "assets/icons/adioLogo.svg"
    welcomeHeader: string = "Land and investment opportuinties in Abu Dhabi"
    welcomeDescription: string =
        `Discover latest Musataha tenders. Ask our AI Assistant Samed guidence. also he can put you through application process.`
    textDirection!: string;

    translateSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private language: LanguageService,
        private title: Title
    ) {
        super(config);
        this.title.setTitle(this.config.getProjectName());
    }

    ngOnInit() {
        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.textDirection = this.config.getDirectionFromLanguage(selectedLanguage.locale)

            Object.assign(this, translate.typography)
        });
    }
}
